import qs from 'qs'
import router from '@/router'
// import http from '@/utils/http.js'
import store from '@/store'
// import { getToken, setToken, setSite, removeToken } from '@/utils/auth'
// import { setToken } from '@/utils/auth'

// 设置APPID
const whiteList = ['/login']
router.beforeEach(async (to, from, next) => {
  // 在白名单，直接进入
  if (whiteList.indexOf(to.path) !== -1) {
    return next()
  }

  const urlParams = qs.parse(window.location.href.split('?')[1])
  if(urlParams.token){
    localStorage.setItem('token', urlParams.token)
    await store.dispatch('user/getCache')
    return next()
  }else{
    router.push({name: 'Login'})
  }

})