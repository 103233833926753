import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: { name: 'Login' }
  },
  {
    path: '/login',
    name: 'Login',
    meta: { title: '登陆'},
    component: () => import('@/views/login')
  },
  {
    path: '/shop',
    name: 'Shop',
    redirect: { name: 'ShopGoods' },
    component: () => import('@/views/shop/index'),
    children: [
      {
        path: 'goods',
        name: 'ShopGoods',
        meta: { title: '商品信息'},
        component: () => import('@/views/shop/list/index'),
      }
    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
