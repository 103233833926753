import axios from '@/utils/http'
const state = {
  bankList: [
    {
      value: 123,
      label: '招商银行',
      icon: 'van-icon-jue-yinhanglogo-5'
    },
    {
      value: 124,
      label: '中国工商银行',
      icon: 'van-icon-jue-yinhanglogo-6'
    },
    {
      value: 125,
      label: '中国建设银行',
      icon: 'van-icon-jue-yinhanglogo-1'
    }
  ],
  bankInfo: {
    123: {
      label: '招商银行',
      icon: 'van-icon-jue-yinhanglogo-5'
    },
    124: {
      label: '中国工商银行',
      icon: 'van-icon-jue-yinhanglogo-6'
    },
    125: {
      label: '中国建设银行',
      icon: 'van-icon-jue-yinhanglogo-1'
    }
  },
  frontUrl: 'https://h5.bk.zhongheinfo.com',
  wxConfig: null
}

const mutations = {
  SET_WXCONFIG: (state, json) => {
    state.wxConfig = json
  }
}
const actions = {
  getWxConfig ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('/wxc/wx/js').then(res => {
        commit('SET_WXCONFIG', res.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
