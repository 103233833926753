export function getToken() {
  return localStorage.getItem('x-auth-token')
}

export function setToken(token) {
  return localStorage.setItem('x-auth-token', token)
}

export function getSite() {
  return localStorage.getItem('x-auth-site')
}

export function setSite(site) {
  return localStorage.setItem('x-auth-site', site)
}

export function removeToken() {
  return localStorage.clear()
}